import {
  isLoggedIn,
  getUserData as getUserDataModel,
} from "../models/general_model";
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};


export const actualDateBr = (date) => {
  if (typeof date === 'undefined') return '';

  let ndata = date.includes(' ') ? date.split(" ") : date.includes('T') ? date.split('T') : null;

  if (!ndata) return '';
  
  let time = ndata[1].split(":");
  let data = ndata[0].split("-");
  return `${data[2]}/${data[1]}/${data[0]} ${time[0]}:${time[1]}`;
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  return isLoggedIn();
};
export const getUserData = () => {
  return getUserDataModel();
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "usuario") return "/";
  if (userRole === "empresa") return "/";
  if (userRole === "admin-empresa") return "/";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const ucfirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTipoProcesso = (tipo_processo) => {
  const tipos = [
    {
      label: "Abertura de conta",
      value: "abertura_conta",
    },
    {
      label: "Conta encerrada",
      value: "conta_encerrada",
    },
    {
      label: "Movimentação de conta",
      value: "movimentacao_conta",
    },
    {
      label: "Contratos",
      value: "contratos",
    },
    {
      label: "Cartão de crédito",
      value: "cartao_credito",
    },
    {
      label: "Cheque especial",
      value: "cheque_especial",
    },
    {
      label: "Movimento de caixa",
      value: "movimento_caixa",
    },
    {
      label: "Administrativo",
      value: "administrativo",
    },
    {
      label: "Financeiro / contabil",
      value: "financeiro",
    },
  ];

  let retorno = "";
  for (var i = 0; i < tipos.length; i++) {
    if (tipos[i].value === tipo_processo) {
      return tipos[i].label;
    }
  }
};

export const getServicoSolicitado = (val) => {
  const tipos = [
    {
      label: "",
      value: "",
    },
    {
      label: "Imagem",
      value: "imagem",
    },
    {
      label: "Malote",
      value: "malote",
    },
    {
      label: "Sedex",
      value: "sedex",
    },
    {
      label: "Transferência entre agências",
      value: "transferencia",
    },
  ];

  let retorno = "";
  for (var i = 0; i < tipos.length; i++) {
    if (tipos[i].value === val) {
      return tipos[i].label;
    }
  }
};

export const getCompanyName = () => {
  return "Iguana Docs";
};

export const getUserDetails = (
  credentials,
  label,
  labelCompany = null,
  labelGroup = null
) => {
  if (labelCompany === null) labelCompany = label;
  if (labelGroup === null) labelGroup = labelCompany;

  let valuesInside = [];
  let retorno = null;
  if (credentials.user) {
    retorno = credentials.user;
    valuesInside = label.split(".");
  } else if (credentials.company) {
    retorno = credentials.company;
    valuesInside = labelCompany.split(".");
  } else if (credentials.company_group) {
    retorno = credentials.company_group;
    valuesInside = labelGroup.split(".");
  }

  valuesInside.forEach((key) => {
    retorno = retorno[key];
  });
  return retorno;
};

export const getUserTypeName = (userType) => {
  if (userType === "empresa") return "Filial";
  if (userType === "admin-empresa") return "Empresa";

  return userType;
};

export const getOnlyTwoNames = (name) => {
  if (!name.includes(" ")) return name;
  let names = name.split(" ");
  return names[0] + " " + names[1];
};

//export const api_default_url = "https://apidocs.iguanait.com.br/api";

export const api_default_url = "https://moreddocs.iguanait.com.br/api";

//export const api_default_url = "http://localhost:8000/api";

//export const getPrefixName = "apidocsv2";
//export const getStorageName = "@apidocsv2";

export const getPrefixName = "moreddocs";
export const getStorageName = "@moreddocs";
