import React from "react";
import axios from "axios";
import { sendRequest } from "../models/general_model";

export default function useAccept() {
  const getAcceptData = () => {
    const accept = localStorage.getItem("syllos@accept");
    if (typeof accept === "undefined" || !accept) return false;

    return accept == 1 ? true : false;
  };

  const removeAcceptData = () => {
    localStorage.removeItem("syllos@accept");
  };

  const setAcceptData = async (accepted) => {
    localStorage.setItem("syllos@accept", accepted ? "1" : "0");

    const response = await sendRequest(
      "/termos",
      "post",
      { ativado: accepted }
    );

    if (!response) return false;

    return true;
  };

  const hasAccepted = async () => {
    if (getAcceptData()) return true;

    const accepted = await sendRequest("/termos", "get");
    if (!accepted) return false;

    return accepted?.ativado ?? false;
  };

  return { hasAccepted, setAcceptData, removeAcceptData };
}
