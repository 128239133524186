import axios from "axios";
import useAccept from "../hooks/useAccept";
import {
  api_default_url,
  getPrefixName,
  getStorageName,
} from "../utility/Utils";

const urls = {
  login: "/login",
  perfil: "/perfil",
  notificacoes: "/notificacoes",
};

const storageName = getStorageName;
const prefixName = getPrefixName;

const clearSession = () => {
  if (localStorage.getItem(`${prefixName}_user`) !== null)
    localStorage.removeItem(`${prefixName}_user`);
  if (localStorage.getItem(`${prefixName}_loggedIn`) !== null)
    localStorage.removeItem(`${prefixName}_loggedIn`);
  if (localStorage.getItem(`${prefixName}_permissions`) !== null)
    localStorage.removeItem(`${prefixName}_permissions`);
};

const saveDadosUser = async (dados) => {
  clearSession();
  const jToken = dados.access_token;
  delete dados.access_token;

  localStorage.setItem(`${prefixName}_user`, JSON.stringify(dados));
  localStorage.setItem(`${getStorageName}`, jToken);
  localStorage.setItem(`${prefixName}_loggedIn`, true);
};

const savePermissions = async (dados) => {
  localStorage.setItem(`${prefixName}_permissions`, JSON.stringify(dados));
};

export const saveDadosSession = async (dados) => {
  localStorage.setItem(`${prefixName}_user`, JSON.stringify(dados));
};

export const logoff = () => {
  clearSession();
  localStorage.removeItem(`${prefixName}@accept`);
  return true;
};

export const login = async (login, password) => {
  const request = {
    usuario: login,
    senha: password,
  };

  const dadosLogin = await axios.post(urls.login, request);

  console.log(dadosLogin.data);
  if (typeof dadosLogin === "undefined") return null;

  if (typeof dadosLogin.data.error !== "undefined") return dadosLogin.data;

  if (dadosLogin.data.user_type !== "admin") {
    saveDadosUser(dadosLogin.data);
    return dadosLogin.data;
  }

  const permissions = await axios.post(urls.login + "/permissions", request);
  if (typeof permissions === "undefined") return null;

  if (typeof permissions.data.error !== "undefined") return permissions.data;

  saveDadosUser(dadosLogin.data);
  savePermissions(permissions.data);
  return dadosLogin.data;
};

export const isLoggedIn = () => {
  return localStorage.getItem(`${prefixName}_loggedIn`) !== null
    ? localStorage.getItem(`${prefixName}_loggedIn`)
    : false;
};

export const getUserType = () => {
  if (!isLoggedIn()) return null;

  const data = JSON.parse(localStorage.getItem(`${prefixName}_user`));
  return data.user_type;
};

export const getUserData = () => {
  if (!isLoggedIn()) return null;

  const data = JSON.parse(localStorage.getItem(`${prefixName}_user`));
  return data;
};

const ability_usuario = () => {
  return [
    {
      action: "listar",
      subject: "home",
    },
    {
      action: "listar",
      subject: "chamados",
    },
    {
      action: "cadastrar",
      subject: "chamados",
    },
    {
      action: "listar",
      subject: "arquivos",
    },
    {
      action: "listar",
      subject: "chat",
    },
  ];
};

const ability_admin_empresa = () => {
  return [
    {
      action: "listar",
      subject: "home",
    },
    {
      action: "listar",
      subject: "usuarios",
    },
    {
      action: "cadastrar",
      subject: "usuarios",
    },
    {
      action: "editar",
      subject: "usuarios",
    },
    {
      action: "excluir",
      subject: "usuarios",
    },
    {
      action: "cadastrar",
      subject: "chamados",
    },
    {
      action: "listar",
      subject: "empresas",
    },
    {
      action: "editar",
      subject: "empresas",
    },
    {
      action: "excluir",
      subject: "empresas",
    },
    {
      action: "cadastrar",
      subject: "empresas",
    },
    {
      action: "listar",
      subject: "chamados",
    },
    {
      action: "listar",
      subject: "arquivos",
    },
    {
      action: "listar",
      subject: "arquivos_usuario",
    },
    {
      action: "listar",
      subject: "chat",
    },
  ];
};

const ability_empresa = () => {
  return [
    {
      action: "listar",
      subject: "home",
    },
    {
      action: "listar",
      subject: "usuarios-filial",
    },
    {
      action: "listar",
      subject: "usuarios",
    },
    {
      action: "editar",
      subject: "usuarios",
    },
    {
      action: "excluir",
      subject: "usuarios",
    },
    {
      action: "cadastrar",
      subject: "chamados",
    },
    {
      action: "cadastrar",
      subject: "usuarios",
    },
    {
      action: "listar",
      subject: "chamados",
    },
    {
      action: "listar",
      subject: "arquivos",
    },
    {
      action: "listar",
      subject: "arquivos_usuario",
    },
    {
      action: "listar",
      subject: "chat",
    },
  ];
};

const ability_admin = () => {
  let permissions = JSON.parse(
    localStorage.getItem(`${prefixName}_permissions`)
  );
  const permissoes = permissions ? [...permissions] : [];
  return [
    {
      action: "listar",
      subject: "home",
    },
    {
      action: "listar",
      subject: "chat",
    },
    ...permissoes,
  ];
};

export const getNotifications = async () => {
  const type = getUserType();

  const notificacoes = await axios.get(type + urls.notificacoes);
  if (typeof notificacoes === "undefined" || notificacoes.data) return [];

  return notificacoes.data;
};

export const getUserAbility = () => {
  const userType = getUserType();

  if (!userType) return null;

  switch (userType) {
    case "admin":
      return ability_admin();
    case "admin-empresa":
      return ability_admin_empresa();
    case "empresa":
      return ability_empresa();
    default:
      return ability_usuario();
  }
};

export const getApiUrl = (parts) => {
  return api_default_url + "/arquivos";
};

export const sendRequest = async (
  url,
  method = "GET",
  data = null,
  headers = null,
  addType = true,
  responseType = null
) => {
  let sendUrl = url[0] === "/" ? url : "/" + url;
  const type = getUserType();
  const config = {
    method: method,
    url: addType ? type + sendUrl : sendUrl,
  };
  if (data !== null) config.data = data;

  if (headers !== null) config.headers = headers;

  if (responseType !== null) config.responseType = responseType;

  const response = await axios(config).catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });

  if (response === null || typeof response === "undefined") {
    console.log(response);
    return null;
  }

  if (typeof response.data === "undefined") {
    console.log(response);
    return response;
  }

  return response.data;
};

export const fileData = async (url) => {
  let urlSender = url.replace(axios.defaults.baseURL, "");

  const config = {
    method: "GET",
    url: urlSender,
    responseType: "blob",
  };

  const response = await axios(config).catch(function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  });

  if (response === null || typeof response === "undefined") {
    console.log(response);
    return null;
  }

  if (typeof response.data === "undefined") {
    console.log(response);
    return response;
  }

  return response.data;
};
